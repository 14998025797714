<template>
  <div ref="contentBody">

    <div v-if="milestoneBoardInfo">
      <b-card-actions :kanban="true" :title="milestoneBoardInfo?.title" action-collapse class="mb-1">
        <b-row>
          <b-col cols="12">
            <!-- search input -->
            <template >
              <div class="header-container" ref="filterBody">
                <div>
                  <template v-if="milestoneBoardInfo?.milestone_id">
<!--                    <b-breadcrumb class="breadcrumb-chevron pl-0">-->
<!--                      <b-breadcrumb-item v-on:click="onShowMilestones">-->
<!--                        Milestone:-->
<!--                        {{-->
<!--                          milestoneBoardInfo?.milestone?.data?.title-->
<!--                        }}</b-breadcrumb-item-->
<!--                      >-->
<!--                      <b-breadcrumb-item v-on:click="onShowBoardDetails" active>-->
<!--                        {{ milestoneBoardInfo?.title }}</b-breadcrumb-item-->
<!--                      >-->
<!--                    </b-breadcrumb>-->

                    <b>
                      {{ milestoneBoardInfo?.title }}
                    </b>
                  </template>
                  <template v-else>
                    <b-breadcrumb class="breadcrumb-chevron pl-0">
                      <b-breadcrumb-item active>
                        <b> {{ milestoneBoardInfo?.title }}</b>
                      </b-breadcrumb-item>
                    </b-breadcrumb>
                  </template>
                </div>

                <div>
                  <span
                      v-for="(member, index) in milestoneBoardInfo?.team
                          ?.data"
                      :key="index"
                  >
                        <template v-if="index <= 2">
                          <b-avatar
                              :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar"
                              size="26"
                              style="margin: 1px"
                          /></template>
                      </span>
                  <span v-if="milestoneBoardInfo?.team_count > 3">
                        <b-link
                            v-on:click="
                            showAllAddedMemberModal(
                              milestoneBoardInfo?.team?.data
                            )
                          "
                        >
                          + {{ milestoneBoardInfo?.team_count - 3 }} more
                        </b-link>
                      </span>
                  <b-button
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      style="margin-left: 5px"
                      v-on:click="showAddBoardMemberModal(milestoneBoardInfo)"
                  >
                    <feather-icon icon="UserPlusIcon" />
                  </b-button>

                  <b-dropdown
                      class="ml-2"
                      variant="link"
                      toggle-class="text-decoration-none board-drop-down"
                      no-caret
                      dropleft
                  >
                    <template v-slot:button-content>
                      <feather-icon
                          icon="MenuIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                      />
                    </template>
                    <template
                        v-if="
                        +milestoneBoardInfo?.sub_tasks_count == 0 &&
                        +milestoneBoardInfo?.tasks_count == 0
                      "
                    >
                      <b-dropdown-item v-on:click="onShowMileStoneModal">
                        <feather-icon icon="UploadIcon" />
                        <span> Import From </span>
                      </b-dropdown-item>
                    </template>
                    <template>
                      <b-dropdown-item v-on:click="onShowBacklog">
                        <feather-icon icon="ListIcon" />
                        <span> Backlog List</span>
                      </b-dropdown-item>
                    </template>
                    <template>
                      <b-dropdown-item v-on:click="onShowPhases">
                        <feather-icon icon="GridIcon" />
                        <span> Phase List</span>
                      </b-dropdown-item>
                    </template>

                    <template>
                      <b-dropdown-item v-on:click="onShowPhaseDuration">
                        <feather-icon icon="WatchIcon" />
                        <span> Phase Duration</span>
                      </b-dropdown-item>
                    </template>
                    <template>
                      <b-dropdown-item v-on:click="onShowActivitylog">
                        <feather-icon icon="SlidersIcon" />
                        <span> Board History</span>
                      </b-dropdown-item>
                    </template>
                    <template>
                      <b-dropdown-item v-on:click="goToTrash">
                        <feather-icon
                            icon="Trash2Icon"
                            class="board-trash-icon"
                        />
                        <span> Board Trash</span>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </div>
              </div>
            </template>
          </b-col>
        </b-row>

<!--        Filter here-->
        <b-row class="mt-1">

          <b-col md="2" lg="2" xs="12">
            <v-select
                v-model="filterGroupBy"
                :options="groupByOptions"
                :reduce="(item) => item.value"
                label="name"
                placeholder="Group By"
                v-on:input="filterByGroup"
                class="custom-font"
            />
          </b-col>

          <b-col md="2" lg="2" xs="12">
            <b-form-input
                v-model="filterTitle"
                placeholder="Search By Title"
                type="text"
                v-on:keyup="filter"
                class="custom-font"
            /></b-col>

          <b-col md="2" lg="2" xs="12">
            <v-select
                v-model="filterStatus"
                :options="taskStatusOptions"
                :reduce="(item) => item.value"
                v-on:input="loadAllPhase"
                label="name"
                placeholder="Status"
                class="custom-font"
            />
          </b-col>

          <b-col md="3" lg="3" xs="12">
            <v-select
                placeholder="Assinged Users"
                v-model="filterAssignedUserId"
                :options="filterMemberIdOptions"
                :reduce="(country) => country.id"
                label="name"
                v-on:input="loadAllPhase"
                class="custom-font"
            >
              <template #option="data">
                <UserSelect :user="data" />
              </template>
            </v-select>
          </b-col>

          <b-col md="3" lg="3" xs="12">

            <flat-pickr
                v-model="filterRangeDate"
                v-on:input="loadAllPhase"
                class="form-control"
                placeholder="Filter Actual Date Range"
                :config="{ mode: 'range'}"
            />

          </b-col>

        </b-row>
<!--        Filter ends here-->
      </b-card-actions>
    </div>

    <div v-if="isMilestoneTaskBoardLoading" class="text-center">
      <b-spinner
          style="width: 3rem; height: 3rem"
          class="mr-1"
          variant="primary"
      />
    </div>

    <div v-else class="scroll-container" :style="{'maxHeight': scrollableTaskHeight}">
      <div class="scrollable-main-container" v-if="phaseList.length > 0">
        <draggable
            :list="phaseList"
            tag="ul"
            class="phase-list-draggable"
            :options="{ direction: 'horizontal', handle: '.phase-card-title' }"
            @change="onPhaseSequenceChange"
        >
          <b-list-group-item
              v-for="(item, index) in phaseList"
              :key="item?.id"
              tag="li"
          >
            <template>
              <div class="phase-list-main-card phase-container" :data-phase-id="item.id">

                <Phase
                    :key="item?.id"
                    :phase="item"
                    :is-subtask="filterGroupBy === 'sub_task'"
                    @create-backlog="addTaskInBacklogModal"
                    @edit-phase="onShowPhaseModal"
                />

                <div
                    class="scrollable-task"
                    @scroll="handleScroll(index)"
                    :style="{ maxHeight: scrollableTaskHeight }"
                    :ref="`scrollableTask${index}`"
                >
                  <!-- Draggable Tasks within Each Phase -->
                  <draggable
                      :list="item.tasks"
                      group="tasks"
                      :options="{ group: { name: 'tasks', pull: true, put: true }, handle: '.task-handle'}"
                      @change="onTaskMoved"
                  >

                    <b-list-group-item
                        style="margin-bottom: 15px"
                        v-for="task in item.tasks"
                        :key="task.id"
                    >

                      <TaskCard
                          :phase="item"
                          :phase-index="index"
                          :phase-length="phaseList.length"
                          :task="task"
                          :is-subtask="filterGroupBy === 'sub_task'"
                          @show-sidebar="onShowTaskSidebar"
                          @show-member="showAddMemberModal"
                          @delete-task="onDeleteTask"
                      />

                    </b-list-group-item>

                    <div class="text-center" v-if="item.loading">
                      <b-spinner
                          style="width: 3rem; height: 3rem"
                          class="mr-1"
                          variant="primary"
                      />
                    </div>

                  </draggable>
                </div>
              </div>
            </template>

            <p class="text-center cursor-pointer font-weight-bold" v-if="item.hasMore && 1===3" @click="handleScroll(index)">Load More...</p>
          </b-list-group-item>
        </draggable>

        <div>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              v-on:click="showPhaseModal"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
      </div>
    </div>
    <!--    </div>-->

<!--    Modal start here-->

    <!--    add new task on backlog-->
    <b-modal
        id="modal-task-board-task"
        centered
        title="Add Task"
        hide-footer
        @hidden="hiddenTaskModal"
        size="lg"
        no-close-on-backdrop
    >
      <validation-observer ref="backlogTask">
        <b-form v-on:submit.prevent="backlogTaskForm">
          <b-row>
            <b-col md="12" lg="12" xs="12">
              <b-form-group label="Title *" label-for="title">
                <ValidationProvider
                    name="title"
                    v-slot="{ errors }"
                    vid="title"
                    rules="required"
                >
                  <b-form-input
                      id="title"
                      type="text"
                      class="custom-font"
                      v-model="title"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter task title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Add/Edit Phase Modal -->
    <b-modal
        id="modal-task-board-phase"
        centered
        :title="modelType === 'editModel' ? 'Edit Phase Information' : 'Add Phase'"
        hide-footer
        @hidden="hiddenPhaseModal"
        no-close-on-backdrop
    >
      <validation-observer ref="milestoneTaskBoardPhase">
        <b-form @submit.prevent="milestoneTaskBoardPhaseForm">
          <b-form-group label="Title *" label-for="title">
            <ValidationProvider
                name="title"
                v-slot="{ errors }"
                rules="required"
            >
              <b-form-input
                  id="title"
                  type="text"
                  v-model="phaseTitle"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Phase Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Type *" label-for="type">
            <ValidationProvider
                v-slot="{ errors }"
                name="Type"
                rules="required"
            >
              <v-select
                  id="is_final"
                  placeholder="Choose a Type"
                  v-model="selectTypeValue"
                  :options="typeValueOption"
                  :reduce="(option) => option.value"
                  label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

<!--    task edit sidebar modal-->
    <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadows
    >
      <div>
        <validation-observer ref="taskEdit">
          <b-form v-on:submit.prevent="taskEditForm" class="mr-1">
            <h4>
              {{
                filterGroupBy === "sub_task"
                    ? "Edit Sub Task Information"
                    : "Edit Task Information"
              }}
            </h4>
            <b-row class="mb-1">
              <b-col md="10" lg="10" xs="12">
                <div id="component-breadcrumbs">
                  <b-breadcrumb class="breadcrumb-chevron">
                    <b-breadcrumb-item active>
                      {{ milestoneBoardInfo?.title }}
                    </b-breadcrumb-item>
                    <b-breadcrumb-item v-if="filterGroupBy === 'sub_task'"
                    >
                      {{ breadCrumTaskTitle }}
                    </b-breadcrumb-item>
                    <b-breadcrumb-item
                        v-on:click="onShowTask(breadCrumShortTitle)"
                    >
                      {{ breadCrumTitle }} - ({{
                        breadCrumShortTitle
                      }})
                    </b-breadcrumb-item>

                    <div
                        v-if="filterGroupBy === 'sub_task'"
                        style="margin-left: 5px"
                    >
                      <feather-icon
                          icon="CopyIcon"
                          @click="onCopySubTaskLink(subTaskId)"
                      />
                    </div>
                    <div v-else style="margin-left: 5px">
                      <feather-icon
                          icon="CopyIcon"
                          @click="onCopyTaskLink(breadCrumShortTitle)"
                      />
                    </div>
                  </b-breadcrumb>
                </div>
              </b-col>

              <b-col md="2" lg="2" xs="12">
                <b-dropdown
                    no-caret
                    variant="outline-primary"
                    :text="filterGroupBy === 'sub_task'
                            ? subTaskPhase?.title
                            : taskPhase?.title"
                    size="sm"
                >
                  <b-dropdown-item
                      v-for="phase in taskPhaseOptions"
                      :key="phase.id"
                      v-on:click="updatePhase(phase)"
                  >
                    {{ phase.title }}
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item v-on:click="onShowPhase">
                    <feather-icon icon="PlusCircleIcon" /> Add More
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>

            <!-- Title -->
            <template>
              <b-row v-if="filterGroupBy === 'sub_task'">
                <b-col md="12" lg="12" xs="12">
                  <b-form-group
                      label="Title "
                      label-for="title"
                      class="required-label"
                  >
                    <ValidationProvider
                        name="title"
                        v-slot="{ errors }"
                        vid="title"
                        rules="required"
                    >
                      <b-form-input
                          id="title"
                          type="text"
                          class="custom-font"
                          v-model="title"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter task title"
                      />
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col md="12" lg="12" xs="12">
                  <b-form-group
                      label="Title "
                      label-for="title"
                      class="required-label"
                  >
                    <ValidationProvider
                        name="title"
                        v-slot="{ errors }"
                        vid="title"
                        rules="required"
                    >
                      <b-form-input
                          id="title"
                          type="text"
                          class="custom-font"
                          v-model="title"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter task title"
                      />
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="filterGroupBy === 'sub_task'">
                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Type" label-for="priority">
                    <b-form-input
                        id="title"
                        type="text"
                        class="custom-font"
                        :value="+sequenceNumber ? 'Sequential' : 'General'"
                        disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                      label="Estimation Point"
                      label-for="point"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="point"
                        vid="point"
                        rules="min_value:0|max_value:9999"
                    >
                      <b-form-input
                          id="point"
                          type="text"
                          v-model="point"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter Estimation Point"
                          class="custom-font"
                      />

                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                      label="Priority "
                      label-for="priority"
                  >
                    <ValidationProvider
                        name="priority"
                        v-slot="{ errors }"
                        vid="priority"
                    >
                      <v-select
                          id="priority"
                          v-model="selectPriorityType"
                          :options="priorityOptionConstants"
                          :reduce="(option) => option.value"
                          placeholder="Select task priority"
                          label="name"
                          class="custom-font"
                      />
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Label" label-for="labels">
                    <ValidationProvider
                        name="labels"
                        v-slot="{ errors }"
                        vid="labels"
                    >
                      <v-select
                          :dir="
                                        $store.state.appConfig.isRTL
                                          ? 'rtl'
                                          : 'ltr'
                                      "
                          id="labels"
                          class="custom-font"
                          placeholder="Add Task Label"
                          v-model="selectLabels"
                          label="name"
                          multiple
                          taggable
                          push-tags
                      >
                      </v-select>
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                      label="Estimation Point"
                      label-for="point"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="point"
                        vid="point"
                        rules="min_value:0|max_value:9999"
                    >
                      <b-form-input
                          id="point"
                          type="text"
                          v-model="point"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter Estimation Point"
                          class="custom-font"
                      />

                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                      label="Observer"
                      label-for="observer"
                  >
                    <ValidationProvider
                        name="observer"
                        v-slot="{ errors }"
                        vid="observer"
                    >
                      <v-select
                          id="observer"
                          v-model="selectObserver"
                          :options="observerOptions"
                          :reduce="(item) => item.id"
                          placeholder="Select Observer"
                          label="name"
                          class="custom-font"
                      ><template #option="data">
                        <UserSelect :user="data" />
                      </template>
                      </v-select>
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                      label="Assigner"
                      label-for="assigner"
                  >
                    <ValidationProvider
                        name="assigner"
                        v-slot="{ errors }"
                        vid="assigner"
                    >
                      <v-select
                          id="assigner"
                          v-model="selectAssignee"
                          :options="assigneeOptions"
                          :reduce="(item) => item.id"
                          placeholder="Select Assigner"
                          label="name"
                          class="custom-font"
                      ><template #option="data">
                        <UserSelect :user="data" />
                      </template>
                      </v-select>
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Assignee" label-for="user_id">
                    <ValidationProvider
                        name="user_id"
                        v-slot="{ errors }"
                        vid="user_id"
                    >
                      <v-select
                          id="user_id"
                          placeholder="Search by employee name or email"
                          v-model="selectMemberIds"
                          :filterable="true"
                          :options="assigneeOptions"
                          :reduce="(item) => item.id"
                          label="name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                      >
                        <template #option="data">
                          <UserSelect :user="data" />
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <template v-if="previousFiles.length > 0">
                <b-row class="mb-2">
                  <b-col>
                    <div class="scrollable-file-container">
                      <div
                          class="uploaded-files mb-2 scrollable-file-content"
                      >
                        <div
                            v-for="(item, index) in previousFiles"
                            :key="index"
                            class="file-preview"
                        >
                          <b-row class="mr-1">
                            <b-col md="8" lg="8" xs="12">
                              <div
                                  v-b-tooltip.hover.bottom="
                                                item.file_name
                                              "
                              >
                                <template
                                    v-if="isPreviousFileImage(item)"
                                >
                                  <img
                                      :src="item?.original_url"
                                      alt="Preview"
                                      width="100"
                                      height="100"
                                  />
                                </template>
                                <template v-else>
                                  <svg
                                      width="100px"
                                      height="100px"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="#000000"
                                  >
                                    <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                    ></g>
                                    <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      <title></title>
                                      <g id="Complete">
                                        <g id="F-File">
                                          <g id="Text">
                                            <g>
                                              <path
                                                  d="M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z"
                                                  fill="none"
                                                  id="File"
                                                  stroke="#000000"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"
                                              ></path>
                                              <line
                                                  fill="none"
                                                  stroke="#000000"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"
                                                  x1="7.9"
                                                  x2="16.1"
                                                  y1="17.5"
                                                  y2="17.5"
                                              ></line>
                                              <line
                                                  fill="none"
                                                  stroke="#000000"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"
                                                  x1="7.9"
                                                  x2="16.1"
                                                  y1="13.5"
                                                  y2="13.5"
                                              ></line>
                                              <line
                                                  fill="none"
                                                  stroke="#000000"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"
                                                  x1="8"
                                                  x2="13"
                                                  y1="9.5"
                                                  y2="9.5"
                                              ></line>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </template>
                              </div>
                            </b-col>
                            <b-col md="3" lg="3" xs="12">
                              <b-button
                                  class="flex-shrink-0 btn-sm"
                                  v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                              "
                                  variant="outline-danger"
                                  @click="removePreviousFile(index)"
                              >
                                <feather-icon icon="XIcon" />
                              </b-button>
                              <br />
                              <br />
                              <b-button
                                  class="flex-shrink-0 btn-sm"
                                  v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                              "
                                  variant="outline-primary"
                                  @click="onShowImage(item)"
                              >
                                <feather-icon icon="EyeIcon" />
                              </b-button>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </template>

              <b-row>
                <b-col>
                  <div class="file-input">
                    <label for="file-upload" class="attach-icon">
                      Attach File
                      <feather-icon
                          icon="PaperclipIcon"
                          class="mr-50"
                          style="color: #7367f0"
                      />
                    </label>
                    <input
                        hidden
                        id="file-upload"
                        type="file"
                        multiple
                        @change="handleFileUpload"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="scrollable-file-container">
                    <div
                        class="uploaded-files mb-2 scrollable-file-content"
                    >
                      <div
                          v-for="(file, index) in uploadedFiles"
                          :key="index"
                          class="file-preview"
                      >
                        <b-row class="mr-1">
                          <b-col md="8" lg="8" xs="12">
                            <div
                                v-b-tooltip.hover.bottom="file.name"
                            >
                              <template v-if="isImage(file)">
                                <img
                                    :src="file.preview"
                                    alt="Preview"
                                    width="100"
                                    height="100"
                                    @click="onShowImage(file)"
                                />
                              </template>
                              <template v-else>
                                <svg
                                    width="100px"
                                    height="100px"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                >
                                  <g
                                      id="SVGRepo_bgCarrier"
                                      stroke-width="0"
                                  ></g>
                                  <g
                                      id="SVGRepo_tracerCarrier"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <title></title>
                                    <g id="Complete">
                                      <g id="F-File">
                                        <g id="Text">
                                          <g>
                                            <path
                                                d="M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z"
                                                fill="none"
                                                id="File"
                                                stroke="#000000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            ></path>
                                            <line
                                                fill="none"
                                                stroke="#000000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                x1="7.9"
                                                x2="16.1"
                                                y1="17.5"
                                                y2="17.5"
                                            ></line>
                                            <line
                                                fill="none"
                                                stroke="#000000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                x1="7.9"
                                                x2="16.1"
                                                y1="13.5"
                                                y2="13.5"
                                            ></line>
                                            <line
                                                fill="none"
                                                stroke="#000000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                x1="8"
                                                x2="13"
                                                y1="9.5"
                                                y2="9.5"
                                            ></line>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </template>
                            </div>
                          </b-col>
                          <b-col md="3" lg="3" xs="12">
                            <b-button
                                class="flex-shrink-0 btn-sm"
                                v-ripple.400="
                                              'rgba(255, 255, 255, 0.15)'
                                            "
                                variant="outline-danger"
                                @click="removeFile(index)"
                            >
                              <feather-icon icon="XIcon" />
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                      label="Description"
                      label-for="description"
                  >
                    <ValidationProvider
                        #default="{ errors }"
                        name="description"
                        vid="description"
                    >
                      <quill-editor
                          v-model="description"
                          :options="editorOption"
                          :style="{
                                        height: '13rem !important',
                                        paddingBottom: '5rem !important',
                                      }"
                      >
                        <div id="toolbar" slot="toolbar">
                                        <span class="ql-formats">
                                          <button class="ql-bold">Bold</button>
                                          <button class="ql-italic">
                                            Italic
                                          </button>
                                          <button class="ql-underline">
                                            Underline
                                          </button>
                                          <button class="ql-strike">
                                            Strike
                                          </button>
                                          <button
                                              class="ql-blockquote"
                                          ></button>
                                          <button
                                              class="ql-list"
                                              value="ordered"
                                          ></button>
                                          <button
                                              class="ql-list"
                                              value="bullet"
                                          ></button>
                                          <button
                                              class="ql-script"
                                              value="sub"
                                          ></button>
                                          <button
                                              class="ql-script"
                                              value="super"
                                          ></button>
                                          <button
                                              class="ql-indent"
                                              value="-1"
                                          ></button>
                                          <button
                                              class="ql-indent"
                                              value="+1"
                                          ></button>
                                          <button
                                              class="ql-direction"
                                              value="rtl"
                                          ></button>
                                          <button
                                              class="ql-align"
                                              value=""
                                          ></button>
                                          <button
                                              class="ql-align"
                                              value="center"
                                          ></button>
                                          <button
                                              class="ql-align"
                                              value="right"
                                          ></button>
                                          <button
                                              class="ql-align"
                                              value="justify"
                                          ></button>
                                          <select class="ql-color"></select>
                                          <select
                                              class="ql-background"
                                          ></select>
                                          <select class="ql-size">
                                            <option value="small" />

                                            <option selected />
                                            <option value="large" />
                                            <option value="huge" />
                                          </select>

                                          <select class="ql-font"></select>
                                          <select class="ql-header">
                                            <option value="1">Heading 1</option>
                                            <option value="2">Heading 2</option>
                                            <option value="3">Heading 3</option>
                                            <option value="4">Heading 4</option>
                                            <option value="5">Heading 5</option>
                                            <option value="6">Heading 6</option>
                                            <option selected>Normal</option>
                                          </select>

                                          <button class="ql-link"></button>
                                        </span>
                        </div>
                      </quill-editor>

                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" lg="12" xs="12">
                  <b-form-group
                      label="Deadline"
                      label-for="deadline"
                  >
                    <ValidationProvider
                        name="deadline"
                        v-slot="{ errors }"
                        vid="deadline"
                    >
                      <b-form-datepicker
                          id="deadline"
                          v-model="deadline"
                          class="form-control custom-font"
                          :state="errors.length > 0 ? false : null"
                          locale="en-US"
                          today-button
                          close-button
                          reset-button
                          placeholder="Deadline"
                      />
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col
                    md="6"
                    lg="6"
                    xs="12"
                >
                  <b-form-group
                      label="Actual Start Date"
                      label-for="actual_start_date"
                  >
                    <ValidationProvider
                        name="actual_start_date"
                        v-slot="{ errors }"
                        vid="actual_start_date"
                    >
                      <b-form-datepicker
                          id="actual_start_date"
                          v-model="actualStartDate"
                          class="form-control custom-font"
                          :state="errors.length > 0 ? false : null"
                          locale="en-US"
                          today-button
                          close-button
                          reset-button
                          placeholder="Actual Start Date "
                      />
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                    md="6"
                    lg="6"
                    xs="12"
                >
                  <b-form-group
                      label="Actual End Date"
                      label-for="actual_end_date"
                  >
                    <ValidationProvider
                        name="actual_end_date"
                        v-slot="{ errors }"
                        vid="actual_end_date"
                    >
                      <b-form-datepicker
                          id="actual_end_date"
                          v-model="actualEndDate"
                          class="form-control custom-font"
                          :state="errors.length > 0 ? false : null"
                          locale="en-US"
                          today-button
                          close-button
                          reset-button
                          placeholder="Actual End Date "
                      />
                      <small class="text-danger">{{
                          errors[0]
                        }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <template
                      v-if="
                        $permissionAbility(
                          MILESTONE_BOARD_TASK_EDIT,
                          permissions
                        ) && filterGroupBy !== 'sub_task'
                      "
                  >
                    <template v-if="isLoading">
                      <b-button
                          class="float-right"
                          variant="primary"
                          disabled
                      >
                        <b-spinner small />
                        Loading...
                      </b-button>
                    </template>

                    <!-- submit button -->
                    <template v-else>
                      <b-button
                          type="submit"
                          class="float-right mt-50"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                      >
                        Save Task
                      </b-button>
                    </template>
                  </template>
                </b-col>
              </b-row>

              <template
                  v-if="
                                $permissionAbility(
                                  MILESTONE_BOARD_SUB_TASK_CREATE,
                                  permissions
                                )
                              "
              >
                <b-row
                    class="mt-1 mb-1"
                    v-if="filterGroupBy !== 'sub_task'"
                >
                  <b-col>
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="btn btn-sm"
                        v-on:click="showSubTaskForm"
                    >
                      <feather-icon
                          icon="BriefcaseIcon"
                          class="mr-50"
                      />
                      Add Sub Task
                    </b-button>
                  </b-col>
                </b-row>
              </template>

              <template v-if="isSubTaskFormLoaded">
                <b-row class="mt-1 mb-1">
                  <b-col md="12" lg="12" xs="12">
                    <validation-observer ref="subTaskAdd">
                      <b-form
                          v-on:submit.prevent="subTaskAddForm"
                      >
                        <b-row
                        ><b-col md="8" lg="8" xs="12">
                          <ValidationProvider
                              name="title"
                              v-slot="{ errors }"
                              vid="title"
                          >
                            <b-form-input
                                id="title"
                                type="text"
                                class="custom-font"
                                v-model="subTaskTitle"
                                :state="
                                                errors.length > 0 ? false : null
                                              "
                                placeholder="Enter Sub Task Title"
                            />
                            <small class="text-danger">{{
                                errors[0]
                              }}</small>
                          </ValidationProvider>
                        </b-col>
                          <b-col md="4" lg="4" xs="12">
                            <template v-if="isSubTaskLoading">
                              <b-button
                                  class="float-right"
                                  variant="primary"
                                  disabled
                              >
                                <b-spinner small />
                                Loading...
                              </b-button> </template
                            ><template v-else>
                            <b-button
                                class="float-right btn-sm"
                                v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                              "
                                variant="primary"
                                v-on:click="closeSubTaskForm"
                            >
                              Cancel
                            </b-button>
                            <b-button
                                type="submit"
                                class="float-right mr-1 btn-sm"
                                v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                              "
                                variant="primary"
                            >
                              Add
                            </b-button>
                          </template>
                          </b-col>
                        </b-row>
                      </b-form>
                    </validation-observer>
                  </b-col>
                </b-row>
              </template>
              <b-row class="mt-1">
                <b-col md="12" lg="12" xs="12">
                  <b-list-group>
                    <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                        v-for="subTask in subTaskList"
                        :key="subTask?.id"
                    >
                      <b-link
                          v-on:click="onShowSubTask(subTask?.milestone_task_board_id, subTask?.task_id, breadCrumShortTitle, subTask?.id, subTask?.short_title)"
                      >{{ subTask?.title }}</b-link
                      >

                      <div
                          class="custom-search d-flex align-items-center justify-content-end"
                      >
                        <b-badge
                            variant="light-primary"
                            class="mr-1"
                        >{{
                            subTask?.phase?.data?.title
                          }}</b-badge
                        >

                        <template
                            v-if="
                                          $permissionAbility(
                                            MILESTONE_BOARD_SUB_TASK_DELETE,
                                            permissions
                                          )
                                        "
                        >
                          <b-button
                              v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                          "
                              variant="danger"
                              class="btn-sm"
                              v-on:click="
                                            onDeleteSubTask(subTask?.id)
                                          "
                          >
                            <feather-icon icon="TrashIcon" />
                          </b-button>
                        </template>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </template>

            <template
                v-if="
                              $permissionAbility(
                                MILESTONE_BOARD_SUB_TASK_EDIT,
                                permissions
                              ) && filterGroupBy === 'sub_task'
                            "
            >
              <template v-if="isLoading">
                <b-button
                    class="float-right"
                    variant="primary"
                    disabled
                >
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                    type="submit"
                    class="float-right mt-2 mb-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                  Save Sub Task
                </b-button>
              </template>
            </template>
            <!-- loading button -->
          </b-form>
        </validation-observer>
      </div>
    </b-sidebar>

<!--    view file modal-->
    <b-modal
        id="file-preview-modal"
        centered
        title="File Preview"
        hide-footer
        @hidden="hiddenFilePreviewModal"
        no-close-on-backdrop
    >
      <template>
        <b-img :src="previewFile" fluid-grow alt="Fluid-grow image" />

        <p class="text-center">
          Open in new
          <b-link :href="previewFile" target="_blank">window</b-link>
        </p>
      </template>
    </b-modal>
<!--    show member modal-->
    <b-modal
        id="modal-add-member-form"
        centered
        title="Assign Task To Member"
        hide-footer
        @hidden="hiddenAddMemberModal"
        no-close-on-backdrop
    >
      <validation-observer ref="addMemberValidation">
        <b-form v-on:submit.prevent="addMemberValidationForm">
          <!-- select member-->
          <b-form-group label="Member" label-for="user_id">
            <ValidationProvider
                name="user_id"
                v-slot="{ errors }"
                vid="user_id"
            >
              <v-select
                  id="user_id"
                  placeholder="Search by employee name or email"
                  v-model="selectMemberIds"
                  :filterable="true"
                  :options="assigneeOptions"
                  :reduce="(item) => item.id"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
              >
                <template #option="data">
                  <UserSelect :user="data" />
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Add
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
        id="modal-task-board-import"
        centered
        title="Import From Another Board"
        hide-footer
        @hidden="hideMileStoneModal"
        no-close-on-backdrop
    >
      <validation-observer ref="milestoneTaskBoardImport">
        <b-form v-on:submit.prevent="milestoneTaskBoardImportForm">
          <b-form-group label="Select Board *" label-for="milestone_task_board">
            <validation-provider
                #default="{ errors }"
                name="milestone task board"
                vid="milestone_task_board_id"
                rules="required"
            >
              <v-select
                  id="milestone_task_board_id"
                  placeholder="Choose A Board"
                  v-model="selectMilestoneBoardId"
                  :options="milestoneBoardIdOption"
                  :reduce="(item) => item.id"
                  label="name"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
        id="modal-all-added-member-form"
        centered
        title="Assigned Members"
        hide-footer
        @hidden="hiddenAllAddedMemberModal"
        no-close-on-backdrop
    >
      <ul>
        <li v-for="(member, index) in assignedMember" :key="index">
          <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26" style="margin: 1px" />
          {{ member.name }}
        </li>
      </ul>
    </b-modal>

    <b-modal
        id="modal-add-board-member-form"
        centered
        title="Add Board Member"
        hide-footer
        @hidden="hiddenAddBoardMemberModal"
        size="lg"
        no-close-on-backdrop
    >
      <validation-observer ref="addMemberValidation">
        <b-form v-on:submit.prevent="addBoardMemberValidationForm">
          <!-- select member-->
          <b-form-group label="Member" label-for="user_id">
            <ValidationProvider
                name="user_id"
                v-slot="{ errors }"
                vid="user_id"
            >
              <v-select
                  id="user_id"
                  placeholder="Search by employee name or email"
                  v-model="selectMemberIds"
                  :options="assigneeOptions"
                  :reduce="(item) => item?.id"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
              >
                <template #option="data">
                  <UserSelect :user="data" />
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Add
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <!--    Modal ends here-->

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BLink,
  BButtonToolbar,
  BButtonGroup,
  BCardText,
  BCardGroup,
  BCardTitle,
  BCardSubTitle,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormDatepicker,
  BBreadcrumb,
  BBreadcrumbItem,
  BSidebar,
  VBToggle,
  VBTooltip,
  BDropdownDivider,
  BImg, BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, size, ext, max_value, min_value } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  MILESTONE_BOARD_UPDATE_TASK_FINAL_PHASE,
  MILESTONE_BOARD_TASK_CREATE,
  MILESTONE_BOARD_TASK_EDIT,
  MILESTONE_BOARD_TASK_DELETE,
  MILESTONE_BOARD_SUB_TASK_CREATE,
  MILESTONE_BOARD_SUB_TASK_DELETE,
  MILESTONE_BOARD_SUB_TASK_EDIT,
} from "@/helpers/permissionsConstant";
import LvColorPicker from "lightvue/color-picker";
import draggable from "vuedraggable";
import { priorityOptionConstants } from "@/helpers/constant/priorityOptionConstant";
import UserSelect from '@/layouts/components/UserSelect.vue'
import flatPickr from 'vue-flatpickr-component'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Phase from "@/layouts/components/task/Phase.vue";
import TaskCard from "@/layouts/components/task/TaskCard.vue"
import phase from "@/layouts/components/task/Phase.vue";
import {formatDateRange} from "@/helpers/helpers";
export default {
  name: "MilestoneTaskBoardDetailsView",
  components: {
    BFormCheckbox,
    TaskCard,
    Phase,
    BCardActions,
    UserSelect,
    BRow,
    BCol,
    LvColorPicker,
    BForm,
    BButton,
    BCard,
    BCardBody,
    BBadge,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,

    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BLink,
    BButtonToolbar,
    BButtonGroup,
    BCardText,
    BCardGroup,
    BCardTitle,
    BCardSubTitle,
    BListGroup,
    BListGroupItem,
    quillEditor,
    BFormTextarea,
    BFormDatepicker,
    BBreadcrumb,
    BBreadcrumbItem,
    BSidebar,
    BDropdownDivider,
    draggable,
    BImg,
    flatPickr
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      debounceTimeout: null,
      scrollableTaskHeight: '',
      contentBodyHeight: '',
      filterBodyHeight: '',

      selectedTask: null,
      selectedSubTask: null,

      MILESTONE_BOARD_UPDATE_TASK_FINAL_PHASE,
      MILESTONE_BOARD_TASK_CREATE,
      MILESTONE_BOARD_TASK_EDIT,
      MILESTONE_BOARD_TASK_DELETE,
      MILESTONE_BOARD_SUB_TASK_CREATE,
      MILESTONE_BOARD_SUB_TASK_DELETE,
      MILESTONE_BOARD_SUB_TASK_EDIT,

      filterRangeDate: null,
      selectMilestoneBoardId: "",
      milestoneBoardIdOption: [],
      //filters
      groupByOptions: [
        { name: "Task", value: "task" },
        { name: "Sub Task", value: "sub_task" },
      ],
      taskStatusOptions: [
        { name: "Completed", value: "completed" },
        { name: "Incomplete", value: "not_completed" },
        { name: "Overdue", value: "overdue" },
      ],

      assignedMember: [],

      isSubtaskActive: false,
      filterGroupBy: "task",
      draggingTaskId: "",
      filterTitle: "",
      filterStatus: "",
      filterAssignedUserId: "",
      filterMemberIdOptions: [],
      filterStartDate: "",
      filterEndDate: "",

      //phase
      modelType: "",
      phaseId: "",
      phaseTitle: "",
      selectTypeValue: "",
      typeValueOption: [
        { name: "General", value: false },
        { name: "Final", value: true },
      ],

      //Sub Task
      breadCrumTaskTitle: "",
      breadCrumTitle: "",
      breadCrumShortTitle: "",
      subTaskId: "",
      subTaskTitle: "",
      taskTitle: "",
      isSubTaskFormLoaded: false,
      subTaskPhase: {},
      sequenceNumber: "",
      subTaskPhaseId: "",

      //milestone task board side bar
      task: "",
      selectObserver: "",
      observerOptions: [],
      milestoneBoardInfo: "",
      taskPhase: {},
      taskPhaseOptions: [],
      taskId: "",
      taskPhaseId: "",
      title: "",
      description: "",
      summary: "",
      type: "",
      selectPriorityType: "",
      priorityOptionConstants,
      point: "",
      startDate: "",
      actualStartDate: "",
      deadline: "",
      actualEndDate: "",
      selectLabels: [],
      labelIdOptions: [],
      searchLabelIdOptions: [],
      selectMemberIds: [],
      memberIdOptions: [],
      uploadedFiles: [],
      taskFiles: [],
      previousFiles: [],
      isMilestoneTaskBoardLoading: false,
      subTaskList: [],
      isSubTaskLoading: false,
      selectAssignee: "",
      assigneeOptions: [],

      //main board phases
      phaseList: [],
      pageLength: 10,

      toggleActualDates: false,

      // file preview
      previewFile: "",
      taskDetailsPreviousFiles: [],

      rows: [],
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },

      denounceTimeout: null
    };
  },
  computed: {
    phase() {
      return phase
    },
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
    }),
    avatar() {
      return require("@/assets/images/avatars/avatar.webp");
    },
  },

  async created() {
    try {
      this.isMilestoneTaskBoardLoading = true;

      const milestoneTaskBoards = await this.$api.get(
          `/api/milestone-task-boards`
      );

      const milestoneBoardIdOption = (
          milestoneTaskBoards?.data?.data || []
      ).map((item) => {
        return {
          name: item?.title,
          id: item?.id,
        };
      });

      this.milestoneBoardIdOption = (milestoneBoardIdOption || []).filter(
          (item) => item?.id !== this.$route.params.id
      );

      this.isMilestoneTaskBoardLoading = false;

      await this.loadItems();

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {

    switchSubtaskFiler() {
      if(this.filterGroupBy === 'sub_task'){
        this.filterGroupBy =  'task'
        this.isSubtaskActive = false
      }else{
        this.filterGroupBy =  'sub_task'
        this.isSubtaskActive = true
      }

      this.filterByGroup()
    },

    filter() {
      // Clear the previous timeout if it exists
      clearTimeout(this.denounceTimeout);

      // Call the loadItems immediately for the first input
      if (!this.denounceTimeout) {
        this.loadAllPhase();
      }

      // Set a new timeout for subsequent calls
      this.denounceTimeout = setTimeout(() => {
        this.loadAllPhase();
      }, 3000); // 300ms debounce time
    },

    loadAllPhase() {
      this.phaseList.forEach((phase, index) => {
        if(phase.loading) return
        this.loadPhase(index)
      });
    },

    async filterByGroup() {
      try {
        this.resetFilters()
        await this.loadItems()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    resetFilters() {
      this.filterStatus = "";
      this.filterTitle = "";
      this.filterAssignedUserId = "";
      this.filterEndDate = "";
      this.filterStartDate = "";
    },

    onShowBacklog() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-backlog",
        params: { id },
      });
    },

    onShowPhases() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-phases",
        params: { id },
      });
    },
    onShowPhaseDuration() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-phase-duration",
        params: { id },
      });
    },

    goToTrash() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-tasks-trash",
        params: { id },
      });
    },

    showAllAddedMemberModal(value) {
      this.assignedMember = value.map((item) => {
        let name = `${item?.name} (${item?.email})`;
        return {
          name,
          avatar: item?.avatar,
          id: item.id,
        };
      });

      this.$bvModal.show("modal-all-added-member-form");
    },

    hiddenAllAddedMemberModal() {
      this.$bvModal.hide("modal-all-added-member-form");
    },

    onShowActivitylog() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-history",
        params: { id },
      });
    },

    onShowMileStoneModal() {
      this.$bvModal.show("modal-task-board-import");
    },
    hideMileStoneModal() {
      this.$bvModal.hide("modal-task-board-import");
      this.selectMilestoneBoardId = "";
    },

    onShowDepartmentDetails() {
      const id = this.milestoneBoardInfo?.department?.data?.id;
      this.$router.push({
        name: "admin-department-details",
        params: { id },
      });
    },
    onShowMilestones() {
      this.$router.push({
        name: "admin-milestones",
      });
    },

    onShowBoardDetails() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-details",
        params: { id },
      });
    },

    milestoneTaskBoardImportForm: async function () {
      this.$refs.milestoneTaskBoardImport.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "This action is one time & irreversible! Do you really want to import another milestone board information here?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$refs.milestoneTaskBoardImport.reset();
              try {
                this.isLoading = true;
                const milestoneTaskBoardId = this.$route.params.id;

                await this.$api.post(
                    `/api/milestone-task-boards/${milestoneTaskBoardId}/import-board-info`,
                    {
                      milestone_task_board_id: this.selectMilestoneBoardId,
                    }
                );
                this.isLoading = false;
                this.loadItems();
                this.hideMileStoneModal();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Milestone Board Info Imported Successfully",
                  },
                });
              } catch (error) {
                this.isLoading = false;
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.milestoneTaskBoardImport.setErrors(
                      error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },

    async showAddBoardMemberModal(value) {
      this.boardId = value?.id;

      this.selectMemberIds = (value?.team?.data).map((item) => item?.id);

      const allUser = await this.getAllUsers();
      const members = allUser?.data.data;

      this.memberIdOptions = members.map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      });

      this.$bvModal.show("modal-add-board-member-form");
    },

    hiddenAddBoardMemberModal() {
      this.$bvModal.hide("modal-add-board-member-form");
      this.resetAddBoardMemberModal();
    },

    addBoardMemberValidationForm: async function () {
      this.$refs.addMemberValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.addMemberValidation.reset();
            this.isLoading = true;

            await this.$api.post("/api/milestone-task-boards/assign-team", {
              milestone_task_board_id: this.boardId,
              user_id: this.selectMemberIds,
            });
            this.isLoading = false;
            this.loadItems();
            this.hiddenAddBoardMemberModal();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Member Added Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.milestoneTaskBoardValidation.setErrors(
                  error?.response?.data?.errors
              );
            }
          }
        }
      });
    },


    subTaskAddForm: async function () {
      this.$refs.subTaskAdd.validate().then(async (success) => {
        if (success) {
          try {
            this.isSubTaskLoading = true;

            await this.$api.post(
                `/api/milestone-task-boards/phases/tasks/${this.taskId}/subtasks`,
                {
                  title: this.subTaskTitle,
                }
            );

            this.isSubTaskLoading = false;
            await this.loadItems();
            await this.resetSidebar(this.taskId);
            this.closeSubTaskForm();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Sub Task Added Successfully",
              },
            });
          } catch (error) {
            this.isSubTaskLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.subTaskAdd.setErrors(error?.response?.data?.errors);
            }
          }
        }
      });
    },
    closeSubTaskForm() {
      this.subTaskTitle = "";
      this.isSubTaskFormLoaded = false;
    },

    addMemberValidationForm: async function () {
      this.$refs.addMemberValidation.validate().then(async (success) => {
        if (!success) return;

        try {
          this.isLoading = true;

          // Determine the appropriate endpoint based on the filterGroupBy value
          const endpoint =
              this.filterGroupBy === "sub_task"
                  ? `api/milestone-task-boards/tasks/subtasks/${this.subTaskId}/assign-team`
                  : `api/milestone-task-boards/tasks/${this.taskId}/assign-team`;

          // Send API request
          const task = await this.$api.post(endpoint, { user_ids: this.selectMemberIds });

          // Handle UI updates on success
          this.isLoading = false

          this.loadPhase(task?.data?.data?.phase?.sequence - 1)
          this.hiddenAddMemberModal();
          this.filter();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "BellIcon",
              variant: "success",
              text: "Member Assigned Successfully",
            },
          });
        } catch (error) {
          // Handle errors
          this.isLoading = false;

          if (error?.response?.data?.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }

          if (error?.response?.data?.errors) {
            this.$refs.milestoneTaskBoardValidation.setErrors(
                error?.response?.data?.errors
            );
          }
        }
      });
    },

    onCopyTaskLink(shortTitle) {
      const task = shortTitle;
      const id = this.taskId;
      const url = this.$router.resolve({
        name: "admin-task-board-task-details",
        params: {
          id,
          task,
        },
      });

      const copiedUrl = window.location.origin + url.href;

      navigator.clipboard.writeText(copiedUrl).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckIcon",
            variant: "success",
            text: "Link Copied Successfully",
          },
        });
      });
    },

    onCopySubTaskLink(subTaskId) {
      const id = subTaskId;
      const url = this.$router.resolve({
        name: "admin-task-board-task-subtask-details",
        params: {
          id,
        },
      });

      const copiedUrl = window.location.origin + url.href;

      navigator.clipboard.writeText(copiedUrl).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckIcon",
            variant: "success",
            text: "Link Copied Successfully",
          },
        });
      });
    },

    async onShowTaskSidebar(value) {
      // Reset common fields
      this.isSubTaskFormLoaded = false;
      this.subTaskTitle = "";
      this.subTaskList = [];
      this.sequenceNumber = "";

      if (this.filterGroupBy === "sub_task") {
        // Set fields for sub-task
        this.subTaskId = value?.id || "";
        this.taskId = value?.task_id || "";
        this.subTaskPhase = value?.phase?.data || "";
        this.taskTitle = value?.task?.data?.title || "";
        this.breadCrumTaskTitle = value?.task?.data?.title || "";
        this.breadCrumTitle = value?.title || "";
        this.breadCrumShortTitle = value?.short_title || "";
        this.sequenceNumber = value?.sequence_num || "";

        this.selectedTask = value?.task?.data
        this.selectedSubTask = value

      } else {
        // Set fields for task
        this.taskId = value?.id || ""
        this.subTaskList = value?.subTasks?.data || []
        this.breadCrumTitle = value?.title || ""
        this.breadCrumShortTitle = value?.short_title || ""
        this.taskPhase = value?.phase?.data || ""
      }

      // Fetch milestone board team and map team members
      const milestoneBoardTeam = await this.getMilestoneBoardTeam();
      const teamMembers = milestoneBoardTeam?.data?.data?.team?.data || []

      this.selectMemberIds = (value?.team?.data || []).map(item => item?.id)
      this.memberIdOptions = teamMembers.map(item => ({
        avatar: item?.avatar,
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        employee_number: item?.employee_number,
        id: item?.id,
        departmentId: item?.department_id,
      }))

      // Set additional task details
      this.title = value?.title || "";
      this.description = value?.description || "";
      this.type = value?.type || "";
      this.selectPriorityType = value?.priority || "";
      this.point = value?.point || "";
      this.startDate = value?.start_date || "";
      this.actualStartDate = value?.actual_start_date || "";
      this.deadline = value?.deadline || "";
      this.actualEndDate = value?.actual_end_date || "";

      // Set labels, observer, assignee, and previous files
      this.selectLabels = (value?.labels?.data || []).map(item => item?.name);
      this.selectObserver = value?.observer_id ? +value?.observer_id : "";
      this.selectAssignee = value?.assigner_id ? +value?.assigner_id : "";
      this.previousFiles = value?.files || [];
    },

    onShowSubTask(board, taskId, task, id, subtask) {

      this.$router.push({
        name: 'admin-board-task-subtask-details',
        params: {
          board,
          taskId,
          task,
          id,
          subtask,
        },
      })
    },

    onShowSubTaskSidebar() {

      const board = this.selectedSubTask?.milestone_task_board_id
      const taskId = this.selectedSubTask?.task_id
      const task = this.selectedTask?.short_title
      const id = this.selectedTask?.id
      const subtask = this.selectedSubTask?.short_title

      this.$router.push({
        name: 'admin-board-task-subtask-details',
        params: {
          board,
          taskId,
          task,
          id,
          subtask,
        },
      })
    },
    onDeleteSubTask(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This Sub Task?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(
                `api/milestone-task-boards/phases/tasks/subtasks/${id}`
            );
            this.loadItems();
            this.resetSidebar(this.taskId);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Sub Task Deleted Successfully",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    onShowTask(shortTitle) {
      const task = shortTitle;

      if (this.filterGroupBy === "sub_task") {
        const id = this.subTaskId;
        this.onShowSubTaskSidebar();
      } else {
        const id = this.taskId;

        const board = this.milestoneBoardInfo?.id

        this.$router.push({
          name: "admin-board-task-details",
          params: {
            board,
            id,
            task,
          },
        });
      }
    },

    showSubTaskForm() {
      this.isSubTaskFormLoaded = true;
    },

    async moveTaskToNextPhase(value) {
      try {

        const endpoint = this.filterGroupBy !== 'sub_task' ?
            `api/milestone-task-boards/phases/tasks/${value?.id}/move-phase`
            : `api/milestone-task-boards/phases/subtasks/${value?.id}/move-phase`

        await this.$api.put(endpoint, {
              flag: "next",
            }
        );

        let currentSequence = value?.phase?.data?.sequence;
        currentSequence = currentSequence === 0 ? currentSequence : currentSequence - 1;

        await this.loadPhase(currentSequence)
        if(currentSequence < this.phaseList.length){
          await this.loadPhase(currentSequence + 1)
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Task Moved to Next Phase Successfully",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async moveTaskToPreviousPhase(value) {
      try {

        const endpoint = this.filterGroupBy !== 'sub_task' ?
            `api/milestone-task-boards/phases/tasks/${value?.id}/move-phase`
            : `api/milestone-task-boards/phases/subtasks/${value?.id}/move-phase`

        await this.$api.put(endpoint,{
              flag: "previous",
            }
        );

        let currentSequence = value?.phase?.data?.sequence;
        currentSequence = currentSequence === 0 ? currentSequence : currentSequence - 1;

        await this.loadPhase(currentSequence)
        if(currentSequence >= 0){
          currentSequence = currentSequence-1
          await this.loadPhase(currentSequence)
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Task Moved to Previous Phase Successfully",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    onDeleteTask(task) {

      this.$swal({
        title: "Warning!",
        text: "Are You Sure To Move This Task To Trash?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(
                `api/milestone-task-boards/phases/tasks/${task?.id}`
            )

            this.loadPhase(task?.phase?.data?.sequence - 1)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Task Moved To Trash Successfully",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    async milestoneTaskBoardPhaseForm() {
      this.$refs.milestoneTaskBoardPhase.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          const milestoneTaskBoardId = this.$route.params.id;
          try {
            if (this.modelType === 'editModel') {
              await this.$api.put(
                  `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/${this.phaseId}/update`,
                  {title: this.phaseTitle, is_final: this.selectTypeValue}
              );
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Phase Information Updated Successfully'
                }
              });
            } else {
              await this.$api.post(
                  `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/store`,
                  {title: this.phaseTitle, is_final: this.selectTypeValue}
              );
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'New Phase Created Successfully'
                }
              });
            }
            // this.loadItems();
            this.hiddenPhaseModal();
          } catch (error) {
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: error?.response?.data?.message
                }
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.milestoneTaskBoardPhase.setErrors(error?.response?.data?.errors);
            }
          } finally {
            this.isLoading = false;
            this.loadItems()
          }
        }
      });
    },

    async onPhaseSequenceChange(data) {
      try {
        let tempPhaseList = [...this.phaseList];
        let num = 0;
        tempPhaseList.forEach((item, index) => {
          if (item && item?.is_final === false) {
            item.sequence = ++num;
          }
        });

        tempPhaseList.sort((a, b) => {
          return a.sequence - b.sequence;
        });
        this.phaseList = [...tempPhaseList];

        const nonFinalPhases = (this.phaseList || []).filter(
            (item) => item?.is_final == false
        );
        const phaseIds = (nonFinalPhases || []).map((item) => {
          if (item?.is_final == false) {
            return item?.id;
          }
        });

        await this.$api.put(
            `api/milestone-task-boards/${this.$route.params.id}/phases/change-phase-sequence`,

            {
              phase_ids: phaseIds,
            }
        );
        // this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async onTaskMoved(event) {

      const taskId = event?.moved?.element?.id || event?.added?.element?.id;
      if (!taskId) return;

      // Find the task card DOM element
      const taskCard = document.querySelector(`[data-task-id="${taskId}"]`);
      if (!taskCard) return; // Exit if taskCard not found

      // Find the parent list-group-item and the next sibling element
      let prevTaskId = null;
      const parentListGroupItem = taskCard.closest('.list-group-item');
      if (parentListGroupItem) {
        const prevSibling = parentListGroupItem.previousElementSibling

        if (prevSibling) {

          const prevTaskCard = prevSibling.querySelector('[data-task-id]');
          if (prevTaskCard) {
            prevTaskId = prevTaskCard.getAttribute('data-task-id');
          }
        }
      }

      // Find the closest ancestor with data-phase-id attribute
      const closestPhaseContainer = taskCard.closest('.phase-container');
      if (!closestPhaseContainer) return; // Exit if no closest phase container is found

      const phaseId = closestPhaseContainer.getAttribute('data-phase-id');
      if (!phaseId) return; // Exit if no phase ID is found

      // Prepare the API URL and task type based on filterGroupBy
      const isSubTask = this.filterGroupBy === "sub_task";
      const url = isSubTask
          ? `api/milestone-task-boards/phases/tasks/subtasks/${taskId}/update-phase`
          : `api/milestone-task-boards/phases/tasks/${taskId}/update-phase`;

      const name = isSubTask ? 'Sub Task' : 'Task';

      try {
          // Prepare form data for the API request
          const formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("phase_id", phaseId);

          // If nextTaskId exists, append it to formData
          if (prevTaskId) {
            formData.append("prev_task_id", prevTaskId); // Correctly appending nextTaskId now
          }

          await this.$api.post(
              url, formData
          );

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "BellIcon",
              variant: "success",
              text: `${name} Phase Update Successfully`,
            },
          })

      } catch (error) {
        if (error?.response?.data?.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      }
    },
    async getMilestoneTaskBoard() {
      const milestoneTaskBoardId = this.$route.params.id;

      return await this.$api.get(
          `/api/milestone-task-boards/${milestoneTaskBoardId}?include=team,phases,department,milestone,task`
      );
    },

    async getAllUsers() {
      return await this.$api.get(`/api/users/active-all`);
    },

    async loadItems() {
      try {
          const milestoneBoardInfo = await this.getMilestoneTaskBoard();
          const phaseData = milestoneBoardInfo?.data?.data?.phases?.data

        // Initialize phases with empty task lists
        this.phaseList = phaseData.map(phase => ({
          ...phase,
          tasks: [],
          loading: false,
          hasMore: true, // Indicates if there are more tasks to load
          currentPage: 1,
        }))
        // Fetch initial tasks for each phase
        phaseData.forEach((phase, index) => {
          this.fetchTasks(index)
        })

        this.taskPhaseOptions = this.phaseList

        this.milestoneBoardInfo = milestoneBoardInfo?.data?.data;
        const teamMembers = this.milestoneBoardInfo?.team?.data;

        this.filterMemberIdOptions = teamMembers.map((item) => {
          return {
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }
        })
        this.observerOptions = this.filterMemberIdOptions;
        // assignee options
        const allUsersInfo = await this.getAllUsers();
        this.assigneeOptions = (allUsersInfo?.data?.data || []).map(
            (item) => {

              //return disable bool if user on board member or not
              const disabled = this.filterMemberIdOptions.find(member => member.id === item?.id)

              return {
                avatar: item?.avatar,
                name: item?.name,
                email: item?.email,
                mobile: item?.mobile,
                employee_number: item?.employee_number,
                id: item?.id,
                departmentId: item?.department_id,
                disabled: !disabled
              }
            }
        );

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    loadPhase(index){

      const phase = this.phaseList[index]
      if(!phase){
        return
      }

      phase.currentPage = 1;
      phase.hasMore = true
      phase.loading = false
      phase.tasks = []

      this.fetchTasks(index)
    },

    async fetchTasks(phaseIndex) {
      const phase = this.phaseList[phaseIndex];
      if (phase.loading || !phase.hasMore) {
        return // Skip if already loading or no more tasks
      }

      phase.loading = true

      try {

        const { startDate, endDate } = formatDateRange(this.filterRangeDate)

        const config = {
          params: {
            show: this.pageLength,
            page: phase.currentPage,
            q: this.filterTitle,
            filterStatus: this.filterStatus,
            filterAssignedUser: this.filterAssignedUserId,
            filterActualStartDate: startDate,
            filterActualEndDate: endDate
          },
        }

        const endpoint = this.filterGroupBy !== 'sub_task' ?
            `/api/phases/${phase.id}/tasks?include=team,phase,subTasks,observer,subtask.task`
            : `/api/phases/${phase.id}/sub-tasks?page=${phase.currentPage}&show=${this.pageLength}&include=team,phase,task,observer,milestoneTaskBoard`

        const taskData = await this.$api.get(endpoint, config)

        const tasks = taskData.data;
        const { current_page, total_pages } = taskData?.data?.meta?.pagination

        phase.tasks = [...phase.tasks, ...tasks?.data];
        phase.currentPage = current_page + 1;
        phase.hasMore = current_page < total_pages

      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        phase.loading = false
      }

    },

    handleScroll(index) {
      const scrollContainer = this.$refs[`scrollableTask${index}`][0];
      if (scrollContainer) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          // Call the debounced function to load more tasks
          this.fetchTasks(index);
        }
      }
    },

    resetPhaseModal() {
      this.phaseTitle = '';
      this.modelType = '';
      this.phaseId = ''
      this.selectTypeValue = null;
    },

    addTaskInBacklogModal() {
      this.title = "";
      this.$bvModal.show("modal-task-board-task");
    },

    hiddenTaskModal() {
      this.$bvModal.hide("modal-task-board-task");
      this.resetTaskModal();
    },

    resetTaskModal() {
      this.title = "";
    },

    onShowPhase() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-phases",
        params: { id },
      });
    },

    onShowPhaseModal(phase) {
      this.modelType = 'editModel';
      this.phaseId = phase?.id;
      this.phaseTitle = phase?.title;
      this.selectTypeValue = phase?.is_final;

      this.$bvModal.show('modal-task-board-phase');
    },

    showPhaseModal() {
      this.$bvModal.show('modal-task-board-phase');
    },

    hiddenPhaseModal() {
      this.$bvModal.hide('modal-task-board-phase');
      this.resetPhaseModal();
    },

    backlogTaskForm: async function () {
      this.$refs.backlogTask.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true
            const milestoneTaskBoardId = this.$route.params.id;
            const formData = new FormData();

            if (this.title) {
              formData.append("title", this.title);
            }

            await this.$api.post(
                `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/tasks`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
            )
            this.isLoading = false

            const backlogItem = this.phaseList.find((item) => item.is_backlog);

            this.loadPhase(backlogItem?.sequence - 1)
            this.hiddenTaskModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Task Created Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.backlogTask.setErrors(error?.response?.data?.errors);
            }
          }
        }
      });
    },

    async resetSidebar(id) {
      try {
        // Clear uploaded files and task files
        this.uploadedFiles = [];
        this.taskFiles = [];

        // Define API endpoint based on filterGroupBy
        const endpoint =
            this.filterGroupBy === "sub_task"
                ? `api/milestone-task-boards/phases/tasks/subtasks/${id}?include=phase,task.phase`
                : `api/milestone-task-boards/phases/tasks/${id}?include=labels,phase,subTasks.phase,assignee`;

        // Fetch task info from the API
        const taskInfo = await this.$api.get(endpoint);

        // Show the task sidebar with the fetched task data
        this.onShowTaskSidebar(taskInfo?.data?.data);
      } catch (error) {
        console.error("Failed to reset sidebar:", error);
        // You can add toast or other error handling logic here
      }
    },

    async updatePhase(value) {
      try {

        const formData = new FormData();
        formData.append("_method", "PUT");

        // Set phase ID based on the filterGroupBy
        const phaseId = value?.id;
        const isSubTask = this.filterGroupBy === "sub_task";

        if (phaseId) {
          formData.append("phase_id", phaseId);
        }

        const endpoint = isSubTask
            ? `api/milestone-task-boards/phases/tasks/subtasks/${this.subTaskId}/update-phase`
            : `api/milestone-task-boards/phases/tasks/${this.taskId}/update-phase`;

        // Make the API call
        await this.$api.post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        //reload the new phase
        let currentSequence = value?.sequence;
        currentSequence = currentSequence === 0 ? currentSequence : currentSequence - 1;
        await this.loadPhase(currentSequence)

        //reload the existing phase
        const currentPhase = this.filterGroupBy === 'sub_task' ? this.subTaskPhase : this.taskPhase
        let currentPhaseSequence = currentPhase?.sequence;
        currentPhaseSequence = currentPhaseSequence === 0 ? currentPhaseSequence : currentPhaseSequence - 1;
        await this.loadPhase(currentPhaseSequence)

        // Reset sidebar and load items
        isSubTask ? await this.resetSidebar(this.subTaskId) : await this.resetSidebar(this.taskId);

        // if(currentSequence < this.phaseList.length){
        //   await this.loadPhase(currentSequence + 1)
        // }

        // Clear phase ID
        isSubTask ? (this.subTaskPhaseId = "") : (this.taskPhaseId = "");
      } catch (error) {
        // Handle error and show error toast
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message || "An error occurred",
          },
        });
      }
    },

    async getMilestoneBoardTeam() {
      const milestoneTaskBoardId = this.$route.params.id;
      return await this.$api.get(
          `/api/milestone-task-boards/${milestoneTaskBoardId}?include=team`
      );
    },

    async showAddMemberModal(value) {
      if (this.filterGroupBy === "sub_task") {
        this.subTaskId = value?.id;
      } else {
        this.taskId = value?.id;
      }
      const milestoneBoardTeam = await this.getMilestoneBoardTeam();

      const teamMembers = milestoneBoardTeam?.data?.data?.team?.data;

      this.selectMemberIds = (value?.team?.data).map((item) => item?.id);
      this.memberIdOptions = teamMembers.map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      });
      this.$bvModal.show("modal-add-member-form");
    },

    hiddenAddMemberModal() {
      this.$bvModal.hide("modal-add-member-form");
      this.resetAddMemberModal();
    },

    resetAddMemberModal() {
      this.taskId = "";
      this.selectMemberIds = [];
    },

    taskEditForm: async function () {
      this.$refs.taskEdit.validate().then(async (success) => {
        if (!success) return;

        try {
          this.isLoading = true;
          const formData = new FormData();
          formData.append("_method", "PUT");

          // Append fields if they exist, otherwise append default values
          const fields = {
            title: this.title,
            description: this.description || "",
            summary: this.summary || "",
            type: this.type || "",
            priority: this.selectPriorityType || "",
            observer_id: this.selectObserver || "",
            assigner_id: this.selectAssignee || "",
            point: this.point || "",
            start_date: this.startDate || "",
            actual_start_date: this.actualStartDate || "",
            deadline: this.deadline || "",
            actual_end_date: this.actualEndDate || "",
          };

          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });

          // Append labels, previous files, and task files
          this.selectLabels.forEach((label) => formData.append("labels[]", label));
          this.previousFiles.forEach((file) =>
              formData.append("previous_files[]", file.id)
          );
          this.taskFiles.forEach((file) => formData.append("files[]", file));

          // Append member IDs
          formData.append("member_id", JSON.stringify(this.selectMemberIds));

          // Determine API endpoint based on task type
          const endpoint =
              this.filterGroupBy === "sub_task"
                  ? `/api/milestone-task-boards/phases/tasks/${this.taskId}/subtasks/${this.subTaskId}`
                  : `/api/milestone-task-boards/phases/tasks/${this.taskId}`;

          // Make API request
          const task = await this.$api.post(endpoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          // Reset UI and show success message
          this.isLoading = false;

          this.loadPhase(task?.data?.data?.phase?.sequence - 1)
          const textTitle = this.filterGroupBy === "sub_task" ? "Sub Task" : "Task";
          await this.resetSidebar(this.filterGroupBy === "sub_task" ? this.subTaskId : this.taskId);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "BellIcon",
              variant: "success",
              text: `${textTitle} Information Updated Successfully`,
            },
          });
        } catch (error) {
          this.isLoading = false;

          // Handle errors
          if (error?.response?.data?.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }

          if (error?.response?.data?.errors) {
            this.$refs.backlogTask.setErrors(error?.response?.data?.errors);
          }
        }
      });
    },

    onShowImage(index) {
      if (this.isPreviousFileImage(index)) {
        this.previewFile = index.original_url;
        this.$bvModal.show("file-preview-modal");
      } else {
        window.open(index.original_url, "_blank");
      }
    },

    hiddenFilePreviewModal() {
      this.previewFile = "";
      this.$bvModal.hide("file-preview-modal");
    },

    // actual dates show
    toggleActualDatesShow() {
      this.toggleActualDates = !this.toggleActualDates;
    },

    /*** File Upload Start ***/
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!this.checkDuplicateFile(file)) {
          this.uploadedFiles.push({
            name: file.name,
            preview: URL.createObjectURL(file),
            type: file.type,
          });

          this.taskFiles.push(file);
        }
      }
    },

    checkDuplicateFile(file) {
      return this.uploadedFiles.some(
          (uploadedFile) => uploadedFile.name === file.name
      );
    },

    isImage(file) {
      return file.type.startsWith("image/");
    },

    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
    },

    //previous file
    isPreviousFileImage(file) {
      return file.mime_type.startsWith("image/");
    },

    removePreviousFile(index) {
      this.previousFiles.splice(index, 1);
    },
    /*** File Upload End***/
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/quill.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.table-cell-margin {
  margin: 8px 0px;
}

.scrollable-task {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 70vh;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
  }
}

.scrollable-task:hover {
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.custom-font-color {
  color: #636363;
}
.custom-font {
  font-size: 13px !important;
}

.file-input {
  display: inline-block;
  position: relative;
}

.attach-icon {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.uploaded-files {
  margin-top: 20px;
}

.file-preview {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
}

#sidebar-right {
  width: 50%;
  padding: 15px;
}
.b-card-sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phase-card-title {
  cursor: grab; /* Set a custom cursor style when hovering over the phase-list-main-card elements */
}

.phase-card-title:active {
  cursor: grabbing; /* Set a different cursor style when the element is actively dragged */
}
.board-drop-down {
  padding: 0;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  margin-top: -45px;
}
.board-trash-icon {
  color: #ff0000;
}
/* carn brun drag and drop css start*/

.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-height: 80vh;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
  }
}

.scroll-container:hover {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e6e1e1;
  }
}

.scrollable-main-container {
  display: flex;
  flex-wrap: nowrap;
}
.phase-list-draggable {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px
}
.phase-list-main-card {
  width: 20rem;
}

.scrollable-main-container ul {
  padding: 0px;
}
.scrollable-main-container .list-group-item {
  padding: 0 5px;
  background: transparent;
  border: none;
}

.phase-list-main-card .card-body {
  padding: 1.5rem 5px;
}
.required-label label::after {
  content: " *";
  color: red;
}
.text-align-end {
  text-align: end;
}

.phase-list-main-card{
  background: transparent;
  box-shadow: none;
}

.task-item{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important; /* Creates a soft shadow */
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: box-shadow 0.3s ease;
  color: rgba(47, 43, 61, 0.9);
  font-weight: 400;
}

.task-sub-task-items .card-body{
  padding: 15px;
}

/* carn brun drag and drop css end*/
</style>
